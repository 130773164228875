<template>
    <RouterView />
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
header {
    background-color: rgba(0, 132, 69, 0.85); /* Verde con trasparenza al 85% */
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer {
    background-color: #363636;
    color: #fff;
    padding: 20px;
    text-align: center;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
</style>
