import { createRouter, createWebHistory } from 'vue-router';
import ImageCarousel from './components/ImageCarousel.vue';
import ImageCarouselByTag from './components/ImageCarouselByTag.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: ImageCarousel,
    },
    {
      path: '/:id',
      name: 'ImagesByTag',
      component: ImageCarouselByTag,
      props: true
    }  ]
});

export default router;
