<template>
  <div style="text-align: center; margin-top:20px;" v-for="(image, index) in images" :key="index">
            <img style="max-width: 100%;" height="60%" :src="image" alt="Image" />
  </div>
</template>

<script setup>
  import axios from 'axios';
  import { ref, onMounted } from 'vue';
  import '@splidejs/vue-splide/css';

  const images = ref([]);

  const fetchImages = async () => {
    try {
      // const tag = props.id || 'default-tag';
      const response = await axios.get(`${process.env.VUE_APP_API_URL}`);
      images.value = response.data.images;
    } catch (error) {
      console.error('Error fetching images:', error);
      images.value = []; // Imposta un array vuoto in caso di errore
    }
  };

  onMounted(fetchImages); // Esegui fetchImages al momento del montaggio del componente
  </script>

