<template>
  <div class="container">
    <h2>Scansiona il codice QR al tuo arrivo</h2>
    <p>Hai solo 15 minuti per scaricare le tue foto!<br/>
      Scan the QR code on arrival, you only have 15 minutes to download your photos!</p>
    <div style="text-align: center; margin-top:20px;" v-for="(image, index) in images" :key="index">
      <img style="max-width: 100%;" height="60%" :src="image" alt="Image"/>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import {ref, onMounted} from 'vue';
import '@splidejs/vue-splide/css';

const props = defineProps({
  id: {
    type: String,
    required: false
  }
});
const images = ref([]);

const fetchImages = async () => {
  try {
    const tag = props.id || 'default-tag';
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/${tag}`);
    images.value = response.data.images;
  } catch (error) {
    console.error('Error fetching images:', error);
    images.value = []; // Imposta un array vuoto in caso di errore
  }
};

onMounted(fetchImages); // Esegui fetchImages al momento del montaggio del componente
</script>

<style scoped>
.container {
  text-align: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
  background: #fffae6;
  padding: 10px;
  border-left: 5px solid #ffb600;
  display: inline-block;
}

.image-container {
  margin-top: 20px;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>